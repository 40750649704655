import React from "react";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router";
import queryString from "query-string";

const StartedTrial = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return <Redirect to={query.redirect || "/dashboard"} />;
};

export default StartedTrial;
